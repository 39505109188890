@mixin monitoring {

  .horizontal-filter-component {

    .datepicker-min-width {
      min-width: 100px;
    }

    .react-datepicker-wrapper {
      width: 100%;

      .react-datepicker__input-container{
        width: 100%;
        input {
          text-align: center;
        }
      }
    }

    .dropdown {
      width: 150px;

      .dropdown-trigger {
        width: 150px;

        .button {
          width: 150px;
        }
      }
    }
  }

  .title.no-margin, .subtitle.no-margin {
    margin: 0;
  }

  .monitoring-overview {
    border: 6px solid $neutral-bg;
    border-radius: 2rem;
    padding: 1.5rem 0.5rem;
  }

  .monitoring-table-empty {
    border: 6px solid $neutral-bg;
    border-radius: 2rem;
    padding: 2rem 0;
  }

  .date-min-width {
    min-width: 140px;
  }

}