// Modal Styles

@mixin modal {


  // New Modal

  .modal-card-head {
    display: block;
    position: relative;

    > h2 {
      margin-left: 2rem;
      margin-right: 1.5rem;
    }

    .step-numbering {
      position: absolute;
      left: 1rem;
    }

    .steps-marker {
      background-color: $primary-light;
      color: #fff;
      height: 2rem;
      width: 2rem;
      align-items: center;
      display: flex;
      border-radius: 50%;
      font-weight: 700;
      justify-content: center;
      z-index: 10;
      left: 1rem;
      top: 1rem;
      position: absolute;
    }

    > button.delete {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }

  .modal-card-body.content {
    margin-bottom: 0;
  }

  // Old Modal
  .popeye-modal-container {
    .narrow-modal {
      width: auto;
    }

    .fixed.modal {
      width: 450px;
    }
  }

  .modal-content {
    position: relative;
    margin-top: -5px;

    > i {
      position: absolute;
    }

    > h2 {
      margin: 0 70px -20px 70px;
    }
  }

  .loading-modal {
    position: relative;
    margin-top: -5px;
    text-align: center;
  }

  .form-error-item {
    position: relative;
    margin: -15px 0 -15px 15px;

    > i {
      color: $error;
      position: absolute;
      top: 10px;
      left: 15px;
    }

    > p {
      display: inline-block;
      margin-left: 35px;
    }
  }
}