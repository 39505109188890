@mixin error {

  .error-msg {

    .error-code {
      margin-bottom: 0;
    }

    .error-body {
      display: inline-block;
      background-color: whitesmoke;
      border-radius: 2rem;
      padding: 1.5rem 2rem 2rem 2rem;
      margin: 1rem;
    }
  }
}