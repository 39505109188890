@mixin bulmaoverrides {
  .xi {

    .tooltip {
      //z-index: 20;
    }

    .column.label:not(:last-child){
      margin-bottom: 0;
    }

    .notification-wrapper {
      &.is-active {
        .notification.maintenance {
          //background-color: #fdc02b;
          background-color: $secondary;
          border-radius: 2rem;
          margin-top: 2rem;
          color: white;
        }
      }

      &.is-inactive {
        display: none;
      }
    }
    button.is-primary {
      &:hover {
        background-color: $secondary;
      }
    }

    .input, .select select {
      &:focus {
        border-color: $secondary;
        box-shadow: 0 0 0 0.125em lighten($secondary, 30%);
      }
    }

    .navbar-item {
      img {
        max-height: 4.5rem;
      }
    }

    footer {
      padding: 2rem 1.5rem 3rem;
      font-size: $fontSizeFootnote;
      color: lighten($neutral-text, 5%);

      .footer-version-nr {
        color: lighten($neutral-text, 20%);
      }

      a {
        text-decoration: none;
        color: lighten($neutral-text, 5%);
        &:hover {
          color: $secondary;
        }
      }
    }

    .content {
      strong {
        color: $primary;
      }
    }
  }

  .is-divider {
    height: 2px;
  }

   // Temporary Navbar fix: untill
  // https://github.com/jgthms/bulma/pull/1183
  // is in bulma core
  .navbar,
  .navbar-menu,
  .navbar-start,
  .navbar-end {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
  }

  .navbar {
    min-height: $navbar-height;

    &.is-transparent {
      a.navbar-item,
      a.navbar-link {
        &:hover,
        &.is-active {
          background-color: transparent !important;
        }
      }

      .navbar-item.has-dropdown {
        &.is-active,
        &.is-hoverable:hover {
          .navbar-link {
            background-color: transparent !important;
          }
        }
      }

      .navbar-dropdown {
        a.navbar-item {
          &:hover {
            background-color: $navbar-dropdown-item-hover-background-color;
            color: $navbar-dropdown-item-hover-color;
          }

          &.is-active {
            background-color: $navbar-dropdown-item-active-background-color;
            color: $navbar-dropdown-item-active-color;
          }
        }
      }
    }
  }

  .navbar-burger {
    display: none;
  }

  .navbar-item,
  .navbar-link {
    align-items: center;
    display: flex;
  }

  .navbar-item {
    &.has-dropdown {
      align-items: stretch;
    }

    &.has-dropdown-up {
      .navbar-link::after {
        transform: rotate(135deg) translate(0.25em, -0.25em);
      }

      .navbar-dropdown {
        border-bottom: $navbar-dropdown-border-top;
        border-radius: $navbar-dropdown-radius $navbar-dropdown-radius 0 0;
        border-top: none;
        bottom: 100%;
        box-shadow: 0 -8px 8px rgba($black, 0.1);
        top: auto;
      }
    }

    &.is-active,
    &.is-hoverable:hover {
      .navbar-dropdown {
        display: block;

        &.is-boxed {
          opacity: 1;
          pointer-events: auto;
          transform: translateY(0);
        }
      }
    }
  }

  .navbar-link {
    &::after {
      @include arrow($navbar-dropdown-arrow);

      margin-top: -0.375em;
      right: 1.125em;
      top: 50%;
    }
  }

  .navbar-menu {
    flex-grow: 1;
    flex-shrink: 0;
    box-shadow: none;
    padding: 0;
  }

  .navbar-start {
    justify-content: flex-start;
    margin-right: auto;
  }

  .navbar-end {
    justify-content: flex-end;
    margin-left: auto;
  }

  .navbar-dropdown {
    background-color: $navbar-dropdown-background-color;
    border-bottom-left-radius: $navbar-dropdown-radius;
    border-bottom-right-radius: $navbar-dropdown-radius;
    border-top: $navbar-dropdown-border-top;
    box-shadow: 0 8px 8px rgba($black, 0.1);
    display: none;
    font-size: 0.875rem;
    left: 0;
    min-width: 100%;
    position: absolute;
    top: 100%;
    z-index: $navbar-dropdown-z;

    .navbar-item {
      padding: 0.375rem 1rem;
      white-space: nowrap;
    }

    a.navbar-item {
      padding-right: 3rem;

      &:hover {
        background-color: $navbar-dropdown-item-hover-background-color;
        color: $navbar-dropdown-item-hover-color;
      }

      &.is-active {
        background-color: $navbar-dropdown-item-active-background-color;
        color: $navbar-dropdown-item-active-color;
      }
    }

    &.is-boxed {
      border-radius: $navbar-dropdown-boxed-radius;
      border-top: none;
      box-shadow: $navbar-dropdown-boxed-shadow;
      display: block;
      opacity: 0;
      pointer-events: none;
      top: calc(100% + (#{$navbar-dropdown-offset}));
      transform: translateY(-5px);
      transition-duration: $speed;
      transition-property: opacity, transform;
    }

    &.is-right {
      left: auto;
      right: 0;
    }
  }

  .navbar-divider {
    display: block;
  }

  .navbar > .container,
  .container > .navbar {
    .navbar-brand {
      margin-left: -1rem;
    }

    .navbar-menu {
      margin-right: -1rem;
    }
  }

  // Fixed navbar
  .navbar {
    &.is-fixed-bottom-desktop,
    &.is-fixed-top-desktop {
      @include navbar-fixed;
    }

    &.is-fixed-bottom-desktop {
      bottom: 0;

      &.has-shadow {
        box-shadow: 0 -2px 3px rgba($black, 0.1);
      }
    }

    &.is-fixed-top-desktop {
      top: 0;
    }
  }

  html.has-navbar-fixed-top-desktop {
    padding-top: $navbar-height;
  }

  html.has-navbar-fixed-bottom-desktop {
    padding-bottom: $navbar-height;
  }

  // Hover/Active states
  a.navbar-item,
  a.navbar-link {
    &.is-active {
      color: $navbar-item-active-color;
    }

    &.is-active:not(:hover) {
      background-color: $navbar-item-active-background-color;
    }
  }

  .navbar-item.has-dropdown {
    &:hover,
    &.is-active {
      .navbar-link {
        background-color: $navbar-item-hover-background-color;
      }
    }
  }

}
