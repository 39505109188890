@mixin base {

  .is-rel-centered  {
    position: relative; left: 50%;
    transform: translateX(-50%);
  }


  html {
    position: relative;
    min-height: 100%;
  }

  body, button, input, select {
    font: $default-font;
    color: $primary;
  }

  h1 {
    color: $primary;
    text-align: center;
  }

  h1, h2, h3, h4 {
    font: $header-font;
  }

  a {
    color: $primary-light;
    &:hover {
      color: $secondary;
      text-decoration: none;
    }
  }

  .xi {

    display: flex;
    min-height: 100vh;
    flex-direction: column;

    > .main {
      flex: 1;
      padding: 0rem 1rem;
      width: 100%;
    }
  }

  .navbar {
    .navbar-language-icon {
      margin-right: 0.5rem;
    }

    .logout-form {
      margin: 0;
      .button {
        &:hover {
          color: $secondary;
          background-color: white;
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }

  .has-vertically-aligned-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .xidentity-logo {
    height: 71px;
    width: 238px;
    padding-bottom: 15px;
  }

  .webid-logo {
    height: 100px;
    width: 170px;
    float: right;
    margin-top: -10px;
  }

  .center-content {
    text-align: center;
    width: 100%;
  }

  .tag.is-light {
    color: $primary;
  }

  .is-margin-top {
    margin-top: 1.5rem !important;
  }

  .is-margin-top-large {
    margin-top: 3rem !important;
  }

  .is-margin-bottom {
    margin-bottom: 1.5rem !important;
  }

  .is-margin-bottom-large {
    margin-bottom: 3rem !important;
  }

  .xi #cookie-accept-banner{
    //display: flex;
    //align-items: center;
    //justify-content: center;
    //min-height: 3rem;
    //position: fixed !important;
    //bottom: 0 !important;
    //left: 0 !important;
    //width: 100% !important;
    //z-index: 999 !important;
    //background: #034ea2 !important;
    //border-top: solid 2px $xiOrange;
    //text-align: center;

    .CookieConsent {
      align-items: center !important;
      //font-size: 0.75rem;
      //line-height: 1.2rem;
      //font-weight: 500;
      //color: rgb(240, 240, 240);
      //margin-right: 5rem;
      //margin-left: 1rem;
      //padding-top: 0.2rem;
      //padding-bottom: 0.2rem;

      a {
        color: rgb(240, 240, 240);
        margin-left: 1rem;
        text-decoration: underline;
        display: inline-block;

        &:hover {
          color: $xiOrange;
        }
      }
    }

    .button-close {
      border: none !important;
      border-radius: 4px !important;
      box-shadow: none !important;
      font-size: 13px !important;
      font-weight: 500 !important;
      background-color: white !important;
      border-color: #dbdbdb !important;
      border-width: 1px !important;
      color: #363636 !important;
      cursor: pointer !important;
      justify-content: center !important;
      padding-bottom: calc(0.375em - 1px) !important;
      padding-left: 0.75em !important;
      padding-right: 0.75em !important;
      padding-top: calc(0.375em - 1px) !important;
      text-align: center !important;
      white-space: nowrap !important;

      &:hover {
        color: white !important;
        background-color: $xiOrange !important;
        border-width: 1px !important;
      }
    }
  }

}






