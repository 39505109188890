// For Form
@mixin multi-label($width) {
  margin: 0 0 3px 0;
  padding: 0px;
  display: inline-block;
  font-weight: bold;
  width: $width;
}

// For webID Form override
@mixin help-after-element($color: $primary-light) {
  content: "\f059";
  font-family: FontAwesome;
  font-size: 20px;
  color: $color;
  position: absolute;
  top: -61px;
  margin-left: -30px;
}
