@mixin login {

  .login-form {
    @include xi-grey-info-window;

    .login-info{
      margin-top: -0.5rem;
      margin-bottom: 1rem;
    }
  }

}