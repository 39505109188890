// Override webID Styles

@mixin overrides {
  .webid {
    margin-top: 10px;
    margin-bottom: 10px;

    .box {
      margin-top: 1.6em;
    }

    p {
      margin-top: 1em;
    }

    h1 {
      margin-top: 1em;
      font-size: 1.8em;
      font-weight: bold;
    }

    h2 {
      margin-top: 1em;
      font-size: 1.5em;
      font-weight: bold;
    }

    h3 {
      margin-top: 1em;
      font-size: 1.3em;
      font-weight: bold;
    }

    > h1, > .p-b-lg > h1 {
      text-align: left;
      padding-left: 25px;
      margin-bottom: 1rem;
    }

    form {
      margin-top: 1em;
    }

    div {
      color: $primary;
    }

    ul {
      list-style: disc;
    }

    > h1:first-of-type {
      background-color: $neutral-bg;
      padding: 10px 25px;
      margin-bottom: -5px;
    }

    > p:first-of-type {
      text-align: center;
    }

  }

  .api-only {
    margin-top: -80px;

    > img, > h1 {
      display: none;
    }
  }

  #conWebRTCButton {
    margin-bottom: 15px;
  }

  .webid {
    position: relative;
  }

  #popupButton {
    position: relative;
    float: right;
  }

  #webrtc-info {
    text-align: center;
  }

  .fiRow {
    text-align: center;
  }

  a.help {
    font-size: 0px;
    text-decoration: none;
    &::after {
      @include help-after-element;
    }
    &:hover::after {
      @include help-after-element($secondary);
    }
  }

  .info_msg {
    position: relative;
    padding-top: 15px;
  }

  .box {
    position: relative;
    background-color: $neutral-bg;
    padding: 0 30px;
  }

  h3 {
    font-size: 17px;
  }

  .todo_list {
    margin-left: 1rem;
  }

  #webrtc_button_box {

    > div:first-child {

      margin-left: 0;

      @include desktop {
        margin-left: 50px;
      }

      @include widescreen{
        margin-left: 100px;
      }


      > div:nth-child(2) {
        margin-left: 0;
        padding: 0;
        text-align: center;
        background: $neutral-bg;


        @include tablet {
          padding: 8px 200px 8px 30px;
          margin-left: 70px;
        }

        @include widescreen {
          padding: 8px 30px 8px 30px;
        }
      }
    }
    > div:nth-child(2) {
      margin-right: 80px;
      float: left !important;

      @include desktop {
        margin-left: 50px;
      }

      @include widescreen {
        float: right !important;
      }
    }
  }

  .fiLabel {
    font-weight: bold;
    display: block;
  }

  .fiInput, #sendTanV, #sendTanToXi {
    width: 220px;
  }

  .contract-partner-info {
    text-align: center;
    font-size: 18px;
    > i {
      color: $secondary;
    }
  }

  #tan-fieldset {
    border-width: 0;
  }
}