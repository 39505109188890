
@mixin status {

  table.customer-statistic-table {
    .summary-element {
      border-top: 0.15rem solid #dbdbdb;
    }
  }


}