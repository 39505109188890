@mixin legal {

 .agb {
    > p:not(.intent-only) {
      text-indent: -2rem;
      margin-left: 2rem;
    }

    > p.intent-only {
      margin-left: 2rem;
    }

    > ul > li {
      margin-left: 2rem;
    }
  }

  .agb, .privacy, .imprint {
    a {
      color: $xiDarkOrange;
      font-weight: bold;
    }
  }
}