@mixin datepicker {

  .react-datepicker__header {
    background-color: $neutral-bg;
  }

  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__day--keyboard-selected {
    background-color: $primary;

  }

  .react-datepicker-popper {
    z-index: 5;
  }

  .react-datepicker__navigation--previous {
    border-right-color: $primary;
    &:hover {
      border-right-color: $secondary;
    }
  }

  .react-datepicker__navigation--next {
    border-left-color: $primary;
    &:hover {
      border-left-color: $secondary;
    }
  }

  .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow {
    border-top-color: $primary;

  }
}

