// DO NOT USE those colors directly, use themable variables like $set-color-primary etc. below
$xiDarkGrey: #464646;
$xiGreen: #82ba37;
$xiErrorRed: #E34234;
$xiOrange: #fdc02b;
$xiDarkOrange: #ffb500;
$xiBlue: #034ea2;
$xiBlueLighter: rgba(3, 78, 162, 0.7);
$xiGreyBackground: #f3f3f3;

// OVERRIDE THE FOLLOWING COLORS BEFORE IMPORTING _variables.scss IN CUSTOMER THEMES
// Do not use the $set-color-xxx variables directly in styles, only use to set derived color values in order to facilitate
// usage without scss copy orgies with native bulma component defaults and custom theming presets

$set-color-primary: $xiBlue !default;
$set-color-primary-light: $xiBlueLighter!default;
$set-color-primary-invert: white !default;
$set-color-secondary: $xiOrange !default;
$set-color-error: $xiErrorRed !default;
$set-color-x-identity: $xiDarkOrange !default;
$set-color-neutral-bg: $xiGreyBackground !default;
$set-color-neutral-text: $xiDarkGrey !default;
$set-font-header:  300 16px/1.55 "Open Sans", sans-serif !default;
$set-font-default: 300 16px/1.55 "Open Sans", sans-serif !default;

// END OVERRIDEABLE STUFF


// Override Bulma properties before importing initial variables
$fontSizeBigHeading: 20px;
$fontSizeHeading: 18px;
$fontSizeText: 16px;
$fontSizeFootnote: 0.75em;
$fontSizeFootnoteMain: 0.85em;


@import "../../../../node_modules/bulma/sass/utilities/initial-variables";

// Override Bulma variables with own colors and properties
// ---------------------------------------------------------------------------------------------------------------------
// FREELY USE ALL VARIABLES BELOW
//----------------------------------------------------------------------------------------------------------------------
$primary: $set-color-primary;
$primary-invert: $set-color-primary-invert;
$primary-light: $set-color-primary-light;
$secondary: $set-color-secondary;

$error: $set-color-error;
$info: $secondary;
$danger: $error;
$brand: $set-color-x-identity;
$neutral-bg: $set-color-neutral-bg;
$neutral-text: $set-color-neutral-text;

$link: $primary;
$title-color: $primary;
$subtitle-color: $primary-light;
$header-font: $set-font-header;
$default-font: $set-font-default;

$dimensions: 16 32 48 64 96 128 256;

$navbar-height: 5rem;

$tablet:	769px;
$desktop:	769px + (2 * $gap);
$widescreen:	960px + (2 * $gap);
$fullhd:		960px + (2 * $gap);

// Notification
$notification-background-color: $secondary;

// Modals
$modal-card-title-color: $primary;

// Content
$content-heading-color: $primary;

// Buttons
$button-hover-color: $secondary;
$button-hover-border-color: $button-hover-color;
$button-focus-color: $secondary;
$button-focus-border-color: $button-focus-color;

// Navbar
$navbar-height: 3.5rem;
$navbar-item-color: $primary;
$navbar-item-hover-color: $secondary;
$navbar-item-hover-background-color: $primary-invert;
$navbar-item-active-color: $secondary;
$navbar-dropdown-item-hover-color: $secondary;
$navbar-dropdown-item-hover-background-color: $primary-invert;
$navbar-z: inherit;

// Steps
$steps-default-color: $neutral-bg;

@mixin variables {

}

@mixin xi-grey-info-window {
  display: inline-block;
  background-color: whitesmoke;
  border-radius: 2rem;
  padding: 1.5rem 2rem 2rem 2rem;
  margin: 1rem;
}

