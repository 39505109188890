@mixin form {

  form {
    margin: 2rem 0.5rem;

    @include tablet {
      margin: 2rem 0;
    }

    // bugfix for firstname field responsiveness alignment
    .is-grouped-multiline {
      .control.is-expanded:not(:last-child) {
        margin-right: 0;

        @include widescreen {
          margin-right: 0.75rem;
        }
      }
    }

    .dobField > div:not([class]) {
      display: flex;

    }

    .checkbox-field {
      margin: 0 15%;
    }

    .field-centered-buttons {
      justify-content: center !important;
      flex-wrap: wrap;
      margin: -0.5rem;

      > .control {
        margin: 0.5rem;
      }
    }

    .field-body.limit-field-dimension {
      @include tablet {
        margin-right: 8rem;
      }


    }
    label.tos-label {

      &:before {
        left: -2rem;
      }

      &:after {
        left: -2rem;
      }

      .tos-label-start {
        margin-left: 1rem;
      }

      a {
        margin: 0 5px;
        display: inline-block;
        i {
          margin-right: 2px;
        }
      }
    }
  }

  .id-form-info {
    padding: 2rem 3rem 3rem 3rem;
    max-width: 40rem;
    margin: 0 auto;
    span, p {
      color: $primary;
    }

    .card-header-title {
      justify-content: space-evenly;
      flex-wrap: wrap;
    }
    .card-content, .card-header {
      p {
        margin-top: 0;
      }
    }


    .xi-x {
      color: $brand;
    }
  }

  .xi-x-without-margin {
    color: $brand;
  }
}