@mixin finished {

  .finished-element-background {
    width: 100%;
    background: $neutral-bg;
    padding: 2rem 2rem;
    margin-top: 25px;
    margin-bottom: 25px;
    border-radius: 2rem;

    a {
      color: $primary;

      &:hover {
        color: $secondary;
      }
    }
  }

  .finished-element {
    width: 100%;
    padding: 10px 25px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
}