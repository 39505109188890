// Accordion
@mixin accordion {

  .fernsignatur-trigger {
    &:hover {
      color: $secondary;
      cursor: help;

      i {
        color: $secondary;
      }
    }
  }

  // Steps Component

  .status-step-component {
    margin-top: 0;
    margin-bottom: 1rem;

    > .status-step-body {
      border: $neutral-bg solid 6px;
      border-radius: 2rem;
      padding: 0 2rem 0.5rem 2rem;
      margin: 0 0.5rem;

      @include tablet {
        margin: 0 0;
      }

      .step-numbering {
        position: absolute;
        left: 0;
        top:0.65rem;
        font-size: 1.25rem;
        float: left;
      }

      .accordion-title {
        margin-left: 2rem;
        margin-right: 1rem;
      }

      .title:not(:last-child), .subtitle:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      .first-inactive-reset {
        background-color: #f3f3f3 !important;
        color: rgba(0, 0, 0, 0.7) !important;
      }
    }

    @include desktop {
        margin-top: -5rem;
    }
  }


  .transition {
    transition: all 0.25s ease-in-out
  }

  .flipIn {
    animation: flipdown 0.5s ease both
  }

  .no-select {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  ul.accordion {
    list-style: none;
    //perspective: 900;
    padding: 0;
    margin: 0 0 0 0;
    > li {
      position: relative;
      padding: 0;
      margin: 0;
      padding-bottom: 4px;
      padding-top: 18px;
      @extend .flipIn;

      &:nth-of-type(1) {
        animation-delay: 0.5s
      }

      &:last-of-type {
        padding-bottom: 0
      }

      .steps-content {
        &:hover {
          cursor: help;

          p {
            //text-decoration: underline;
            text-decoration-color: $secondary;
            color: $secondary;
          }

          i {
            color: $secondary;
          }
        }
      }

      > i.accordion-icon.is-active {
        position: absolute;
        transform: translate(-6px, 0);
        margin-top: 16px;
        right: 0;

        &:before, &:after {
          content: "";
          @extend .transition;
          position: absolute;
          background-color: $primary;
          width: 3px;
          height: 9px;
        }

        &:before {
          transform: translate(-2px, 0) rotate(45deg)
        }

        &:after {
          transform: translate(2px, 0) rotate(-45deg)
        }
      }

      > input[type=checkbox] {
        position: absolute;
        cursor: pointer;
        width: 100%;
        height: 50%;
        z-index: 1;
        opacity: 0;

        &:checked {
          height: 100%;
          & ~ div.exp {
            margin-top: 0;
            max-height: 0;
            opacity: 0;
            transform: translate(0, 50%);
          }

          & ~ i.accordion-icon.is-active {
            &:before {
              transform: translate(2px, 0) rotate(45deg)
            }

            &:after {
              transform: translate(-2px, 0) rotate(-45deg)
            }
          }
        }
      }
    }

    h2 {
      display: block;
      background-color: white;
      margin: 0;
      cursor: pointer;
      @extend .no-select;
    }

    div.exp {
      position: relative;
      overflow: hidden;
      max-height: 800px;
      @extend .transition;
      opacity: 1;
      transform: translate(0, 0);
      margin-top: 14px;
      z-index: 2;
    }
  }
}